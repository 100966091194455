<template>
  <div ref="vote" class="vote-container">
    <button
      class="vote__likes mr-4 mr-md-2 btn btn-link p-0 mr-2 vote"
      :class="{ disabled: !voteEnabled || voteInfoData === -1 }"
      :disabled="!voteEnabled || voteInfoData === -1"
      @click="like"
    >
      <i class="fa fa-thumbs-o-up" aria-hidden="true"></i>
      {{ likesData }}
    </button>
    <button
      class="vote__dislikes btn btn-link p-0"
      :class="{ disabled: !voteEnabled || voteInfoData === 1 }"
      :disabled="!voteEnabled || voteInfoData === 1"
      @click="dislike"
    >
      <i class="fa fa-thumbs-o-down" aria-hidden="true"></i>
      {{ dislikesData }}
    </button>
  </div>
</template>

<script lang="ts">
import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import IVoteService from "@/application/IVoteService";

enum VoteValue {
  LIKE = 1,
  DISLIKE = -1,
  NEUTRAL = 0,
}

@Component
export default class VoteView extends Vue {
  @Inject("IVoteService") voteService!: IVoteService;
  @Prop({ required: true }) private messageIdHash!: string;
  @Prop({ required: true }) private mlistName!: string;
  @Prop({ required: true }) private likes!: number;
  @Prop({ required: true }) private dislikes!: number;
  @Prop({ default: false }) private enabled!: boolean;
  public voteInfoData: number | undefined = 0;
  public likesData = 0;
  public dislikesData = 0;

  @Watch("enabled")
  onEnabledChanged() {
    this.callVoteInfo();
  }

  @Watch("likes")
  onLikesChanged(like: number) {
    this.callVoteInfo();
    this.likesData = like;
  }

  @Watch("dislikes")
  onDislikesChanged(dislike: number) {
    this.callVoteInfo();
    this.dislikesData = dislike;
  }

  public get voteEnabled() {
    return this.enabled;
  }

  async callVoteInfo() {
    if (this.voteEnabled)
      this.voteInfoData = await this.voteService.get_vote_info(
        this.messageIdHash
      );
  }

  like() {
    let voteToSend = 0;
    if (this.voteInfoData !== VoteValue.LIKE) {
      voteToSend = 1;
    }

    this.vote("onVoteLike", voteToSend);
  }

  dislike() {
    let voteToSend = 0;
    if (this.voteInfoData !== VoteValue.DISLIKE) {
      voteToSend = -1;
    }

    this.vote("onVoteDislike", voteToSend);
  }

  private vote(
    event: "onVoteLike" | "onVoteDislike",
    voteValue: VoteValue
  ): void {
    this.voteService
      .vote(this.mlistName, this.messageIdHash, voteValue)
      .then((res) => {
        this.callVoteInfo();
        this.likesData = res.likes;
        this.dislikesData = res.dislikes;
      });
    this.$emit(event, {
      idHash: this.messageIdHash,
      mailingListName: this.mlistName,
    });
  }

  private async mounted(): Promise<void> {
    this.callVoteInfo();
    this.likesData = this.likes;
    this.dislikesData = this.dislikes;
  }
}
</script>

<style lang="scss" scoped>
.vote {
  &__likes {
    color: var(--success);
  }

  &__dislikes {
    color: var(--danger);
  }
}

.disabled {
  color: var(--disabled);
}
</style>
