<template>
  <div
    class="my-subscriptions nav-tooltip right"
    data-title="My Subscription"
    v-if="subscriptions.length"
  >
    <button class="btn btn-link nav-link text-left" @click.prevent="toggle">
      <span class="fa fa-newspaper my-subscriptions__icon"></span>
      <span class="nav-item-text"> My Subscriptions </span>
      <span :class="subscriptionArrowClass"></span>
    </button>
    <ul :class="subscriptionsItemsClass">
      <li
        class="nav-item my-subscriptions__items__item nav-item-text"
        v-for="(item, key) in subscriptionItems"
        :key="`subscription-entry-${key}`"
      >
        <a class="nav-link" :href="item.listUrl">
          <div
            :class="{
              'd-inline-block': true,
              'text-break': true,
              'text-wrap': true,
              active: item.activeLink,
            }"
          >
            {{ item.displayName }}
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import Component from "vue-class-component";
import { Prop, Vue, Watch } from "vue-property-decorator";
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";
import ISubscription from "@/domain/ISubscription";

const DEFAULT_MY_SUBSCRIPTIONS_ITEMS_CLASS = ["my-subscriptions__items"];
const DEFAULT_MY_SUBSCRIPTIONS_ARROW_CLASS = [
  "fa",
  "fa-angle-down",
  "float-right",
  "my-subscriptions__arrow",
  "nav-item-text",
];

const ACTIVE_URL_PATH_MATCHER_REGEX = /^\/mailing-list\/([^/]+)\//;

type Subscription = ISubscription & { activeLink: boolean };

@Component({
  components: { LoadingSkeleton },
})
export default class MySubscriptions extends Vue {
  @Prop({ required: true }) private value!: boolean;
  @Prop({ required: true }) private subscriptions!: Array<ISubscription>;
  @Prop({ required: false, default: "" }) private currentUrlPath!: string;

  private expanded = false;
  private subscriptionsItemsClass = [...DEFAULT_MY_SUBSCRIPTIONS_ITEMS_CLASS];

  private mounted(): void {
    this.expanded = this.value;
  }

  private get subscriptionItems(): Array<Subscription> {
    return this.subscriptions
      .sort((a, b) => {
        const displayNameA = a.displayName ?? "";
        const displayNameB = b.displayName ?? "";
        return displayNameA.localeCompare(displayNameB);
      })
      .map((subscription) => ({
        ...subscription,
        activeLink: this.isSubscriptionLinkActive(subscription),
      }));
  }

  private isSubscriptionLinkActive(subscription: ISubscription): boolean {
    const listName = this.currentUrlPath.match(
      ACTIVE_URL_PATH_MATCHER_REGEX
    )?.[1];
    if (!listName) {
      return false;
    }
    return listName === subscription.listName;
  }

  private get subscriptionArrowClass(): Array<string> {
    return this.expanded
      ? [...DEFAULT_MY_SUBSCRIPTIONS_ARROW_CLASS, "expanded "]
      : [...DEFAULT_MY_SUBSCRIPTIONS_ARROW_CLASS];
  }

  private toggle() {
    this.expanded = !this.expanded;
  }

  @Watch("expanded")
  private onExpandedChange(value: boolean): void {
    this.$emit("input", value);

    this.subscriptionsItemsClass = value
      ? [...DEFAULT_MY_SUBSCRIPTIONS_ITEMS_CLASS, "expanded"]
      : [...DEFAULT_MY_SUBSCRIPTIONS_ITEMS_CLASS];
  }

  @Watch("subscriptionsItemsClass")
  private onSubscriptionsItemsClassChange(value: string[]): void {
    this.enableScrollAfterExpansionTransition(value);
  }

  /**
   * Enable scroll after the expansion CSS transition has completed in order to prevent the
   * scroll showing up during the transition.
   * @param subscriptionsItemsClass
   * @private
   */
  private async enableScrollAfterExpansionTransition(
    subscriptionsItemsClass: string[]
  ): Promise<void> {
    await MySubscriptions.delay(500);
    if (
      subscriptionsItemsClass.includes("expanded") &&
      !subscriptionsItemsClass.includes("scrollable")
    ) {
      this.subscriptionsItemsClass = [
        ...this.subscriptionsItemsClass,
        "scrollable",
      ];
    }
  }

  private static delay(time: number): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(resolve, time);
    });
  }

  @Watch("value")
  onValueChange(value: boolean) {
    this.$nextTick(() => {
      this.expanded = value;
    });
  }
}
</script>

<style scoped lang="scss">
.my-subscriptions {
  color: #ffffff;
  button {
    color: #ffffff !important;
    padding: 0.5rem 1rem;
    width: calc(100% - 1.5rem);

    &:focus {
      box-shadow: none;
    }
  }

  &__icon {
    width: 2.2rem;
  }

  &__items {
    list-style-type: none;
    max-height: 0;
    transition: max-height 0.5s ease-out;
    overflow-y: hidden;
    &.expanded {
      max-height: 260px;
      transition: max-height 0.5s ease-in;
    }

    &.scrollable {
      overflow-y: auto;
    }

    &__item {
      width: 100% !important;
      div.active {
        border-bottom: 3px solid #007bbd;
      }
    }
  }

  &__arrow {
    width: 0.6rem !important;
    transform: rotate(0deg);
    transition-delay: 300ms;
    transition-duration: 500ms;
    &.expanded {
      transform: rotate(180deg);
      transition-delay: 300ms;
      transition-duration: 500ms;
    }
  }
}
</style>
