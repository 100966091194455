<template>
  <div id="wrapper">
    <div id="sidebar-wrapper" class="d-md-flex flex-column d-flex">
      <div class="sidebar-brand d-flex">
        <div class="d-flex" style="flex: 1; align-items: center">
          <a class="navbar-brand h4" href="/">Component Catalog</a>
        </div>
        <div class="d-flex" style="align-items: center">
          <a
            href="#menu-toggle-sidebar"
            class="btn"
            id="menu-toggle-sidebar"
            aria-label="Toggle sidebar navigation"
            aria-expanded="true"
          >
            <span class="sr-only">Toggle sidebar navigation</span>
            <span class="icon-open">
              <i class="fas fa-chevron-left"></i>
            </span>
          </a>
        </div>
      </div>

      <div id="sidebar">
        <ul class="nav flex-column nav-pills">
          <li class="nav-item">
            <router-link to="/" class="nav-link" active-class="active" exact
              >ProfileSummary</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              to="/component-profile-details/"
              class="nav-link"
              active-class="active"
              >ProfileDetails</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              to="/component-profile-posting-activity/"
              class="nav-link"
              active-class="active"
              >ProfilePostingActivity</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              to="/component-profile-subscriptions/"
              class="nav-link"
              active-class="active"
              >ProfileSubscriptions</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              to="/component-profile-posting-activity-mobile/"
              class="nav-link"
              active-class="active"
              >ProfilePostingActivityMobile</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              to="/component-profile-delete-account/"
              class="nav-link"
              active-class="active"
              >ProfileDeleteAccount</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              to="/component-section-iframe-container/"
              class="nav-link"
              active-class="active"
              >SectionIframeContainer</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              to="/component-message/"
              class="nav-link"
              active-class="active"
              >Message</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              to="/component-news-feed-view-switcher/"
              class="nav-link"
              active-class="active"
              >NewsFeedViewSwitcher</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              to="/component-my-subscriptions/"
              class="nav-link"
              active-class="active"
              >MySubscriptions</router-link
            >
          </li>
        </ul>
      </div>
    </div>

    <main id="page-content-wrapper">
      <div class="contextual">
        <div
          class="container contextual__container contextual__container--full-width"
        >
          <div class="container_group-left">
            <a
              href="#menu-toggle"
              class="btn"
              id="menu-toggle"
              style="padding: 11px"
              aria-label="Toggle sidebar navigation"
            >
              <span class="sr-only">Toggle sidebar navigation</span>
              <span class="icon-close"
                ><i class="fas fa-chevron-right"></i
              ></span>
            </a>
            <a
              class="contextual__h1-link contextual__h1-link--mobile-only"
              href=""
            >
              <h1 class="contextual__h1">Component Catalog</h1>
            </a>
          </div>
          <div class="container_group-right">
            <div class="contextual__navbar-mobile">
              <div class="dropdown contextual__dropdown">
                <a
                  class="btn btn-secondary dropdown-toggle omnibar-contextual-hamburger__toggle"
                  href="#"
                  role="button"
                  id="mobileContextualMenu"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                </a>
                <div
                  class="dropdown-menu omnibar-contextual__wrap"
                  aria-labelledby="mobileContextualMenu"
                  style="top: 96px; display: none"
                >
                  <ul class="omnibar-contextual-navigation-mobile-nav">
                    <li class="omnibar-contextual-navigation-mobile-nav__item">
                      <a
                        class="omnibar-contextual-navigation-mobile-nav__link"
                        href="../demo/"
                        >Index</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid p-4">
        <div class="d-flex flex-column align-items-center p-4">
          <router-view></router-view>
        </div>
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class App extends Vue {}
</script>

<style lang="scss">
#app {
}
</style>
