<template>
  <div class="my-subscriptions-container">
    <MySubscriptions
      :subscriptions="subscriptions"
      currentUrlPath="/mailing-list/mylist-long-name@example.com/"
      v-model="expanded"
    />
    <li>List above is expanded ? {{ expanded }}</li>
  </div>
</template>

<script lang="ts">
import MySubscriptions from "@/components/MySubscriptions.vue";
import { Component, Vue } from "vue-property-decorator";
import ISubscription from "@/domain/ISubscription";

@Component({ components: { MySubscriptions } })
export default class MySubscriptionsContainerCatalog extends Vue {
  private expanded = true;

  private subscriptions: Array<ISubscription> = [
    {
      listId: "mylist.example.com",
      listName: "mylist@example.com",
      listUrl: "/mailing-list/mylist@example.com/",
      archived: true,
      roles: ["member"],
      displayName: "Mylist",
      description: "My testing list",
      coverImageUrl:
        "/media/uploads/2022/8/5/cover_John_Doe_born_John_Nommensen_Duchac.jpeg",
      bannerImageUrl:
        "/media/uploads/2022/8/5/banner_Screen_Shot_2022-08-04_at_12.11.38_am.png",
    },
    {
      listId: "apnic-transfers.anydomain.com",
      listName: "apnic-transfers@anydomain.com",
      listUrl: "/mailing-list/apnic-transfers@anydomain.com/",
      archived: true,
      roles: ["member"],
      displayName: "Apnic-transfers",
      description: "APNIC Transfers",
      coverImageUrl: undefined,
      bannerImageUrl: undefined,
    },
    {
      listId: "mylist.example.com",
      listName: "mylist-long-name@example.com",
      listUrl: "/mailing-list/mylist@example.com/",
      archived: true,
      roles: ["member"],
      displayName: "Mailing list with a really long name",
      description: "My testing list",
      coverImageUrl:
        "/media/uploads/2022/8/5/cover_John_Doe_born_John_Nommensen_Duchac.jpeg",
      bannerImageUrl:
        "/media/uploads/2022/8/5/banner_Screen_Shot_2022-08-04_at_12.11.38_am.png",
    },
    {
      listId: "apnic-transfers.anydomain.com",
      listName: "any-unique-list@anydomain.com",
      listUrl: "/mailing-list/any-unique-list@anydomain.com/",
      archived: true,
      roles: ["member"],
      displayName: "Apnic-transfers",
      description: "APNIC Transfers",
      coverImageUrl: undefined,
      bannerImageUrl: undefined,
    },
    {
      listId: "mylist.example.com",
      listName: "mylist@example.com",
      listUrl: "/mailing-list/mylist@example.com/",
      archived: true,
      roles: ["member"],
      displayName: "Mylist",
      description: "My testing list",
      coverImageUrl:
        "/media/uploads/2022/8/5/cover_John_Doe_born_John_Nommensen_Duchac.jpeg",
      bannerImageUrl:
        "/media/uploads/2022/8/5/banner_Screen_Shot_2022-08-04_at_12.11.38_am.png",
    },
    {
      listId: "apnic-transfers.anydomain.com",
      listName: "apnic-transfers@anydomain.com",
      listUrl: "/mailing-list/apnic-transfers@anydomain.com/",
      archived: true,
      roles: ["member"],
      displayName: "Apnic-transfers",
      description: "APNIC Transfers",
      coverImageUrl: undefined,
      bannerImageUrl: undefined,
    },
    {
      listId: "mylist.example.com",
      listName: "mylist@example.com",
      listUrl: "/mailing-list/mylist@example.com/",
      archived: true,
      roles: ["member"],
      displayName: "list name in lower case",
      description: "My testing list",
      coverImageUrl:
        "/media/uploads/2022/8/5/cover_John_Doe_born_John_Nommensen_Duchac.jpeg",
      bannerImageUrl:
        "/media/uploads/2022/8/5/banner_Screen_Shot_2022-08-04_at_12.11.38_am.png",
    },
    {
      listId: "apnic-transfers.anydomain.com",
      listName: "apnic-transfers@anydomain.com",
      listUrl: "/mailing-list/apnic-transfers@anydomain.com/",
      archived: true,
      roles: ["member"],
      displayName: "Apnic-transfers",
      description: "APNIC Transfers",
      coverImageUrl: undefined,
      bannerImageUrl: undefined,
    },
    {
      listId: "mylist.example.com",
      listName: "mylist@example.com",
      listUrl: "/mailing-list/mylist@example.com/",
      archived: true,
      roles: ["member"],
      displayName: "another list name in lower case",
      description: "My testing list",
      coverImageUrl:
        "/media/uploads/2022/8/5/cover_John_Doe_born_John_Nommensen_Duchac.jpeg",
      bannerImageUrl:
        "/media/uploads/2022/8/5/banner_Screen_Shot_2022-08-04_at_12.11.38_am.png",
    },
    {
      listId: "apnic-transfers.anydomain.com",
      listName: "apnic-transfers@anydomain.com",
      listUrl: "/mailing-list/apnic-transfers@anydomain.com/",
      archived: true,
      roles: ["member"],
      displayName: "Apnic-transfers",
      description: "APNIC Transfers",
      coverImageUrl: undefined,
      bannerImageUrl: undefined,
    },
    {
      listId: "mylist.example.com",
      listName: "mylist@example.com",
      listUrl: "/mailing-list/mylist@example.com/",
      archived: true,
      roles: ["member"],
      displayName: "Mylist",
      description: "My testing list",
      coverImageUrl:
        "/media/uploads/2022/8/5/cover_John_Doe_born_John_Nommensen_Duchac.jpeg",
      bannerImageUrl:
        "/media/uploads/2022/8/5/banner_Screen_Shot_2022-08-04_at_12.11.38_am.png",
    },
    {
      listId: "apnic-transfers.anydomain.com",
      listName: "apnic-transfers@anydomain.com",
      listUrl: "/mailing-list/apnic-transfers@anydomain.com/",
      archived: true,
      roles: ["member"],
      displayName: "Apnic-transfers",
      description: "APNIC Transfers",
      coverImageUrl: undefined,
      bannerImageUrl: undefined,
    },
    {
      listId: "mylist.example.com",
      listName: "mylist@example.com",
      listUrl: "/mailing-list/mylist@example.com/",
      archived: true,
      roles: ["member"],
      displayName: "Mylist",
      description: "My testing list",
      coverImageUrl:
        "/media/uploads/2022/8/5/cover_John_Doe_born_John_Nommensen_Duchac.jpeg",
      bannerImageUrl:
        "/media/uploads/2022/8/5/banner_Screen_Shot_2022-08-04_at_12.11.38_am.png",
    },
    {
      listId: "apnic-transfers.anydomain.com",
      listName: "apnic-transfers@anydomain.com",
      listUrl: "/mailing-list/apnic-transfers@anydomain.com/",
      archived: true,
      roles: ["member"],
      displayName: "Apnic-transfers",
      description: "APNIC Transfers",
      coverImageUrl: undefined,
      bannerImageUrl: undefined,
    },
    {
      listId: "mylist.example.com",
      listName: "mylist@example.com",
      listUrl: "/mailing-list/mylist@example.com/",
      archived: true,
      roles: ["member"],
      displayName: "Mylist",
      description: "My testing list",
      coverImageUrl:
        "/media/uploads/2022/8/5/cover_John_Doe_born_John_Nommensen_Duchac.jpeg",
      bannerImageUrl:
        "/media/uploads/2022/8/5/banner_Screen_Shot_2022-08-04_at_12.11.38_am.png",
    },
    {
      listId: "apnic-transfers.anydomain.com",
      listName: "apnic-transfers@anydomain.com",
      listUrl: "/mailing-list/apnic-transfers@anydomain.com/",
      archived: true,
      roles: ["member"],
      displayName: "Apnic-transfers",
      description: "APNIC Transfers",
      coverImageUrl: undefined,
      bannerImageUrl: undefined,
    },
  ];
}
</script>

<style lang="scss" scoped>
.my-subscriptions-container {
  width: 285px;
  height: 800px;
  background-color: #262626;
  color: #ffffff;
}
</style>
