<template>
  <SectionIframeContainer :src="src" :title="title" />
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import SectionIframeContainer from "@/components/SectionIframeContainer.vue";
@Component({
  components: { SectionIframeContainer },
})
export default class SectionIframeContainerCatalog extends Vue {
  private src = "/accounts/email/";
  private title = "Manage email addresses";
}
</script>

<style scoped></style>
