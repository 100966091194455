import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import IProfile from "@/domain/IProfile";
import { isWebLink } from "@/utils/validations";

const SPLIT_CAMEL_CASE_TEXT_REGEX = /([a-z])([A-Z])/g;

const ENGLISH_FLUENCY_KEY_VALUES: { [key: string]: string } = {
  "very well": "Very well - Fluent",
  most: "I can understand most English and have English conversations comfortably",
  some: "I can understand some English and have basic English conversations",
  little: "I can understand little English and need assistance",
};

const AGE_GROUP_KEY_VALUES: { [key: string]: string } = {
  "18-24": "18 - 24 years old",
  "25-34": "25 - 34 years old",
  "35-44": "35 - 44 years old",
  "45-54": "45 - 54 years old",
  "55+": "Over 55",
};

const ADDITIONAL_INFO_DISPLAY_ORDER = [
  "gender",
  "ageGroup",
  "economy",
  "englishFluency",
  "jobTitle",
  "organisation",
  "twitter",
  "linkedIn",
];

type AdditionalInformationItem = { label: string; value: string };

@Component
export class ProfileDetailsMixIn extends Vue {
  @Prop({ default: undefined }) protected profile!: IProfile | undefined;

  get additionalInformationItems(): Array<AdditionalInformationItem> {
    const items: Array<AdditionalInformationItem> = [];
    if (this.profile) {
      const mapFieldItem = new Map<string, { field: string; value: string }>();
      this.profile.additionalInformation.forEach((item) =>
        mapFieldItem.set(item.field, item)
      );

      ADDITIONAL_INFO_DISPLAY_ORDER.forEach((field) => {
        const item = mapFieldItem.get(field);
        if (item) {
          items.push({
            label: this.labelFromFieldName(item.field),
            value: this.displayableValue(item),
          });
        }
      });
    }
    return items;
  }

  private labelFromFieldName(field: string): string {
    if (field === "linkedIn") {
      return field;
    }

    return field.replace(SPLIT_CAMEL_CASE_TEXT_REGEX, "$1 $2");
  }

  private displayableValue(item: {
    field: string;
    value: string | null;
  }): string {
    if (item.field === "gender") {
      return (
        item.value?.charAt(0)?.toUpperCase() ?? "" + item.value?.slice(1) ?? ""
      );
    }

    if (item.field === "ageGroup") {
      return item.value ? AGE_GROUP_KEY_VALUES[item.value] : "";
    }

    if (item.field === "englishFluency") {
      return item.value ? ENGLISH_FLUENCY_KEY_VALUES[item.value] : "";
    }

    return item.value ?? "";
  }

  private isWebLink(value: string): boolean {
    return isWebLink(value);
  }
}
