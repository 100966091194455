<template>
  <ProfileDeleteAccount @onDelete="onDelete" />
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import ProfileDeleteAccount from "@/components/ProfileDeleteAccount.vue";

@Component({
  components: { ProfileDeleteAccount },
})
export default class ProfileDeleteAccountContainerCatalog extends Vue {
  private onDelete(): void {
    console.log("Delete action triggered");
  }
}
</script>

<style lang="scss" scoped>
div {
  background-color: #ffffff;
}
</style>
