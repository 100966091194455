import DOMPurify from "dompurify";

export default class SanitizeHtmlContentService {
  public static sanitizeHtmlContent(htmlContent: string): string {
    const allowedTags = [
      "img",
      "a",
      "b",
      "u",
      "strong",
      "em",
      "i",
      "strike",
      "ul",
      "ol",
      "li",
      "dl",
      "dt",
      "dd",
      "sub",
      "sup",
      "blockquote",
      "code",
      "div",
      "span",
      "br",
      "p",
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "table",
      "tbody",
      "tr",
      "td",
      "pre",
    ];

    DOMPurify.addHook("uponSanitizeAttribute", (node, data) => {
      if (node.tagName === "IMG" && data.attrName === "src") {
        return;
      }
      if (node.tagName === "A" && data.attrName === "href") {
        return;
      }

      // Allow class 'md__paragraph' in 'p' tags
      if (
        node.tagName === "P" &&
        data.attrName === "class" &&
        data.attrValue === "md__paragraph"
      ) {
        return;
      }
      data.keepAttr = false;
    });

    return DOMPurify.sanitize(htmlContent, {
      ALLOWED_TAGS: allowedTags,
    });
  }
}
