const isNonEmptyASCIIString: (value: string) => boolean = (value) => {
  return (
    typeof value === "string" &&
    value.trim().length > 0 &&
    /^[\x00-\x7F]*$/.test(value) === true
  );
};

export const isWebLink: (value: string) => boolean = (value) => {
  const SERVER_VALIDATION = /^(https?:\/\/)\S+/i;
  return (
    value !== undefined &&
    isNonEmptyASCIIString(value) &&
    SERVER_VALIDATION.test(value)
  );
};
