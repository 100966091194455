<template>
  <div class="card mb-md-5">
    <div class="card-header p-0" :id="`heading_${id}`">
      <h2 class="mb-0">
        <button
          class="btn btn-link btn-block text-left d-flex d-md-block justify-content-between collapsed"
          type="button"
          data-toggle="collapse"
          :data-target="`#collapse_${id}`"
          aria-expanded="true"
          :aria-controls="`#collapse_${id}`"
        >
          <span class="mr-0 mr-md-2">{{ title }}</span>
          <i class="fas fa-angle-up"></i>
        </button>
      </h2>
    </div>

    <div
      :id="`collapse_${id}`"
      class="collapse"
      :aria-labelledby="`heading_${id}`"
    >
      <div class="card-body p-3">
        <iframe :src="src"> </iframe>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SectionIframeContainer extends Vue {
  @Prop() private src!: string;
  @Prop() private title!: string;

  get id(): string {
    return (Math.random() + 1).toString(36).substring(5);
  }
}
</script>

<style scoped lang="scss">
@import "../assets/scss/variables";

.card {
  max-width: $section-max-width;
  width: 100%;
  box-shadow: none;
  border: 0;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.2),
    0px 1px 3px rgba(50, 50, 71, 0.1);
  .card-header {
    background-color: #ffffff;

    button {
      text-decoration: none;
      i {
        transition-duration: 500ms;
        transition-property: transform;
      }
      &.collapsed {
        i {
          transform: rotate(180deg);
        }
      }
    }
  }

  iframe {
    width: 100%;
    height: 20rem;
    @media (min-width: $breakpoint-md) {
      height: 12rem;
    }
    border-style: none;
  }
}
</style>
