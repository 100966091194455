<template>
  <ProfileSubscriptions :subscriptions="subscriptions" />
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import ISubscription from "@/domain/ISubscription";
import ProfileSubscriptions from "@/components/ProfileSubscriptions.vue";
@Component({
  components: { ProfileSubscriptions },
})
export default class ProfileSubscriptionsContainerCatalog extends Vue {
  private subscriptions: Array<ISubscription> = [
    {
      listId: "apnic-talk.list.apnic.net",
      listName: "apnic-talk@list.apnic.net",
      listUrl: "/mailing-list/apnic-talk@list.apnic.net/",
      archived: true,
      roles: ["member"],
      displayName: "apnic-talk",
      description: "General discussion on APNIC",
    },
    {
      listId: "pacnog.pacnog.org",
      listName: "pacnog@pacnog.org",
      listUrl: "/mailing-list/pacnog@pacnog.org/",
      archived: true,
      roles: ["member"],
      displayName: "pacnog",
      description: "Pacific Network Operation Group",
    },
  ];
}
</script>

<style lang="scss" scoped>
ul {
  background-color: #ffffff;
}
</style>
