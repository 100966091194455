<template>
  <ul class="w-100 p-0 mb-0">
    <li
      class="d-flex w-100 subscription p-3 align-items-center"
      v-for="(subscription, key) in subscriptionWithAvatar"
      :key="key"
    >
      <div class="flex-fill d-none d-md-block subscription__avatar w-25">
        <div class="subscription__avatar__container">
          <div
            class="subscription__avatar__image"
            v-if="subscription.avatar.type == `image`"
            :style="{
              'background-image': `url('${subscription.avatar.value}')`,
            }"
          ></div>
          <div
            v-else-if="subscription.avatar.type == `text`"
            class="subscription__avatar__text"
          >
            {{ subscription.avatar.value }}
          </div>
        </div>
      </div>
      <div class="flex-fill subscription__display-name d-flex flex-column w-50">
        <span class="d-none d-md-block" v-if="subscription.displayName">
          {{ subscription.displayName }}
        </span>
        <span class="d-none d-md-block">{{ subscription.listName }}</span>
        <a :href="subscription.url" class="d-md-none">{{
          subscription.displayName
        }}</a>
      </div>
      <div
        class="flex-fill d-none d-md-block subscription__description w-50 align-middle"
      >
        {{ subscription.description }}
      </div>
      <div
        class="flex-fill d-none d-md-block subscription__action w-25 align-middle"
      >
        <a :href="subscription.url" class="btn btn-outline-primary">View</a>
      </div>
    </li>
    <li class="p-0 pt-3 p-md-3" v-if="subscriptionWithAvatar.length === 0">
      No subscriptions yet.
    </li>
  </ul>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import ISubscription from "@/domain/ISubscription";

type Subscription = ISubscription & {
  avatar: { type: string; value: string };
  url: string;
};

@Component
export default class ProfileSubscriptions extends Vue {
  @Prop({ default: [] }) private subscriptions!: Array<ISubscription>;

  get subscriptionWithAvatar(): Array<Subscription> {
    return this.subscriptions.map((subscription) => ({
      ...subscription,
      avatar: ProfileSubscriptions.getAvatar(subscription),
      url: `/mailing-list/${subscription.listName}/`,
    }));
  }

  private static getAvatar(subscription: ISubscription): {
    type: string;
    value: string;
  } {
    if (subscription.coverImageUrl) {
      return {
        type: "image",
        value: subscription.coverImageUrl,
      };
    } else if (subscription.displayName) {
      const words = subscription.displayName.split("-", 2);
      const avatarText = words
        .map((word) => word.substring(0, 1).toUpperCase())
        .join("");
      return {
        type: "text",
        value: avatarText,
      };
    }

    return { type: "", value: "" };
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.subscription {
  border-bottom: 0.1rem solid $grey-border;

  &__avatar {
    max-width: 4rem;
    &__text {
      color: #ffffff;
      background-color: #b3b3b3;
      font-weight: 500;
      font-size: 1.2rem;
      width: 2.8rem;
      height: 2.8rem;
      text-align: center;
      padding-top: 0.6rem;
    }

    &__image {
      width: 2.8rem;
      aspect-ratio: 1;
      background-size: contain;
      background-color: white;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &__display-name {
    span:first-child {
      color: var(--primary);
      @media (min-width: $breakpoint-md) {
        font-weight: 600;
        color: $font-color-default;
      }
    }
  }

  &__action {
    max-width: 4rem;
  }
}
</style>
