<template>
  <div>
    <div v-if="isAuthenticated">
      <span v-if="getMessageReport.length <= 0 && !flaggedByModerator"
        ><button type="button" class="report-btn" @click="onShowModal">
          Report this post
        </button></span
      >
    </div>
    <transition name="modal-slide">
      <div class="modal" v-if="showModal">
        <div>
          <div class="modal__content">
            <span class="modal__content__check-icon"
              ><i class="fa fa-exclamation-triangle mb-3" aria-hidden="true"></i
            ></span>
            <div class="modal__content__support-text">
              <p class="modal__content__support-text__report mb-2">
                Report this post
              </p>
              <div class="modal__content__support-text__verify">
                <p>Are you sure you want to report this post?</p>
                <span
                  class="modal__content__support-text__verify__support-text"
                >
                  This post will be reviewed by the moderator and removed if it
                  violates the
                  <a
                    href="https://www.apnic.net/community/participate/mailinglists/code-of-conduct/"
                    target="_blank"
                    >Code of Conduct.
                  </a>
                </span>
              </div>
              <div class="modal__content__form-group mt-3 mb-4">
                <label class="modal__content__form-group__label">Reasons</label>
                <input type="text" v-model="reason" :class="reasonInputClass" />
              </div>
              <div class="modal__content__actions">
                <button
                  class="modal__content__actions__cancel"
                  @click="onClosedModal"
                >
                  Cancel
                </button>
                <button
                  class="modal__content__actions__confirm btn-primary"
                  @click="onConfirmReportModal"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="modal-slide">
      <div class="modal" v-if="successReport">
        <div class="modal__success_report_content">
          <span class="modal__success_report_content__check-icon"
            ><i class="fa fa-check-circle mb-3" aria-hidden="true"></i
          ></span>
          <div class="modal__success_report_content__support-text">
            <p class="modal__success_report_content__support-text__reported">
              This post has been reported.
            </p>
            <span
              class="modal__success_report_content__support-text__thank_you_text"
            >
              Thank you for your contribution. This post has been sent to the
              moderator’s reported queue for review.
            </span>
          </div>
          <div class="modal__success_report_content__actions">
            <button class="btn-primary" @click="onClickedOk">OK</button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Inject, Prop } from "vue-property-decorator";
import IMessageReportService from "@/application/IMessageReportService";
import IMessageReport from "@/domain/IMessageReport";
import ISecurityService from "@/application/ISecurityService";

@Component
export default class ReportPostUserModalView extends Vue {
  @Inject("IMessageReportService") messageReportService!: IMessageReportService;
  @Inject("ISecurityService") securityService!: ISecurityService;
  @Prop({ required: true }) idHash!: string;
  @Prop({ required: true }) mList!: string;
  @Prop({ required: true }) flaggedByModerator!: boolean;
  private isAuthenticated = false;
  private showModal = false;
  private successReport = false;
  private reason = "";
  private getMessageReport: IMessageReport[] = [];

  async beforeMount(): Promise<void> {
    this.securityService.getUser().subscribe((user) => {
      this.isAuthenticated = !!user;
    });
    this.getMessageReport =
      await this.messageReportService.getUserMessageReport(
        this.mList,
        this.idHash,
        true
      );
  }

  private get reasonInputClass(): string[] {
    return !this.reason
      ? ["is-invalid", "form-control"]
      : ["form-control", "modal__content__form-group"];
  }

  private onShowModal(): void {
    this.showModal = true;
  }

  private onClosedModal(): void {
    this.showModal = false;
  }
  private async onConfirmReportModal(): Promise<void> {
    if (this.reason) {
      await this.messageReportService.reportMessage(
        this.mList,
        this.idHash,
        this.reason
      );
      this.onClosedModal();
      this.successReport = true;
      this.reason = "";
    }
    this.getMessageReport =
      await this.messageReportService.getUserMessageReport(
        this.mList,
        this.idHash,
        true
      );
  }
  private onClickedOk(): void {
    this.successReport = false;
  }
}
</script>

<style scoped lang="scss">
@import "../assets/scss/variables.scss";
.modal-slide-enter-active,
.modal-slide-leave-active {
  transition: all 0.3s;
}
.modal-slide-enter,
.modal-slide-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}
.report-btn {
  color: #007bbd;
  padding: 0;
  background: none;
  border: none;
}
.modal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 3001 !important;
  background-color: rgba(0, 0, 0, 0.5);
  &__content {
    position: relative;
    margin: 15% auto;
    padding: 20px;
    width: 80%;
    max-width: 400px;
    height: 362px;
    background-color: #fff;
    border-radius: 2px;
    @media (max-width: $breakpoint-md) {
      width: 80%;
      max-width: 400px;
      height: auto;
      max-height: 400px;
      margin-top: 50%;
    }
    &__check-icon {
      color: #ffc107;
      margin-bottom: 20px;
    }
    &__support-text {
      display: block;
      gap: 8px;
      &__report {
        font-size: 18px;
        font-weight: bold;
        line-height: 28px;
      }
      &__verify {
        color: #808080;
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
        &__support-text {
          font-size: 14px;
          font-weight: normal;
          line-height: 20px;
        }
      }
    }
    &__form-group {
      &__label {
        font-size: 14px;
      }
      &__label:after {
        content: "*";
        color: red;
      }
    }
    &__actions {
      display: flex;
      gap: 8px;
      button {
        border: 1px;
        width: 170px;
        height: 44px;
      }
    }
  }
  &__success_report_content {
    position: relative;
    margin: 15% auto;
    padding: 20px;
    width: 80%;
    max-width: 400px;
    height: 244px;
    background-color: #fff;
    border-radius: 2px;
    z-index: 3001 !important;
    @media (max-width: $breakpoint-md) {
      width: 80%;
      max-width: 400px;
      height: auto;
      max-height: 300px;
      margin-top: 50%;
    }
    &__check-icon {
      color: #208836;
      margin-bottom: 20px;
    }
    &__support-text {
      margin-bottom: 32px;
      @media (max-width: $breakpoint-md) {
        margin-bottom: 20px;
      }
      &__reported {
        font-size: 18px;
        font-weight: bold;
        line-height: 28px;
        margin-bottom: 8px;
      }
      &__thank_you_text {
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        color: #808080;
      }
    }
    &__actions {
      display: flex;
      button {
        border: 1px;
        width: 352px;
        height: 44px;
      }
    }
  }
}
</style>
