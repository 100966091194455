<template>
  <div class="w-100">
    <p>
      Are you sure you want to delete your account? This will remove your
      account along with all your subscriptions.
    </p>
    <button
      class="btn btn-danger"
      @click.prevent="deleteAccount"
      :disabled="disabled"
    >
      Delete Account
      <div
        v-if="deleting"
        class="spinner-border spinner-border-sm"
        role="status"
      >
        <span class="sr-only">Deleting...</span>
      </div>
    </button>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ProfileDeleteAccount extends Vue {
  private deleting = false;

  get disabled(): boolean {
    return this.deleting;
  }

  private deleteAccount(): void {
    this.deleting = true;
    this.$emit("onDelete");
  }
}
</script>
