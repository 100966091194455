import VueRouter from "vue-router";
import ProfileSummary from "@/components/ProfileSummary.vue";
import ProfileDetails from "@/components/ProfileDetails.vue";
import ProfilePostingActivity from "@/components/ProfilePostingActivity.vue";
import ProfilePostingActivityMobile from "@/components/ProfilePostingActivityMobile.vue";
import SectionIframeContainerCatalog from "@/catalog/SectionIframeContainerCatalog.vue";
import ProfileSubscriptionsContainerCatalog from "@/catalog/ProfileSubscriptionsContainerCatalog.vue";
import MessageContainerCatalog from "@/catalog/MessageContainerCatalog.vue";
import ProfileDeleteAccountContainerCatalog from "@/catalog/ProfileDeleteAccountContainerCatalog.vue";
import NewsFeedViewSwitcherContainerCatalog from "@/catalog/NewsFeedViewSwitcherContainerCatalog.vue";
import MySubscriptionsContainerCatalog from "@/catalog/MySubscriptionsContainerCatalog.vue";

const routerFactory = (): VueRouter => {
  const router = new VueRouter({
    mode: "history",
    base: "/",
    routes: [
      {
        path: "/",
        component: ProfileSummary,
        name: "ProfileSummary",
      },
      {
        path: "/component-profile-details/",
        component: ProfileDetails,
        name: "ProfileDetails",
      },
      {
        path: "/component-profile-posting-activity/",
        component: ProfilePostingActivity,
        name: "ProfilePostingActivity",
      },
      {
        path: "/component-profile-posting-activity-mobile/",
        component: ProfilePostingActivityMobile,
        name: "ProfilePostingActivityMobile",
      },
      {
        path: "/component-section-iframe-container/",
        component: SectionIframeContainerCatalog,
        name: "SectionIframeContainer",
      },
      {
        path: "/component-profile-subscriptions/",
        component: ProfileSubscriptionsContainerCatalog,
        name: "ProfileSubscriptions",
      },
      {
        path: "/component-profile-delete-account/",
        component: ProfileDeleteAccountContainerCatalog,
        name: "ProfileDeleteAccount",
      },
      {
        path: "/component-message/",
        component: MessageContainerCatalog,
        name: "MessageContainerCatalog",
      },
      {
        path: "/component-news-feed-view-switcher/",
        component: NewsFeedViewSwitcherContainerCatalog,
        name: "NewsFeedViewSwitcher",
      },
      {
        path: "/component-my-subscriptions/",
        component: MySubscriptionsContainerCatalog,
        name: "MySubscriptionsViewSwitcher",
      },
    ],
  });
  return router;
};

export default routerFactory;
