<template>
  <section
    class="content-section mb-5 p-4 d-flex flex-column flex-md-row align-items-md-center"
  >
    <template v-if="profileSummary">
      <div
        class="content-section__avatar d-flex flex-column align-items-center"
      >
        <div
          v-if="profileSummary.imageUrl"
          class="d-flex flex-column align-items-center"
        >
          <img
            :src="profileSummary.imageUrl"
            class="rounded-circle"
            width="120"
          />
          <a v-if="!userId" href="https://en.gravatar.com/emails/"
            >Edit on gravatar.com</a
          >
        </div>
        <img v-else src="@/assets/img/avatar.svg" />
      </div>
      <div class="content-section__summary mt-3 mt-md-0">
        <div
          class="d-flex flex-column flex-md-row align-items-center align-items-md-stretch mb-4"
        >
          <div class="flex-fill mb-4 mb-md-0">
            <h1 class="mb-0">{{ profileSummary.displayName }}</h1>
          </div>
          <div
            class="flex-fill text-right d-flex flex-column flex-md-row align-items-center justify-content-end mb-3 mb-md-0"
          >
            <span class="content-section__joined mr-1">Joined on</span>
            {{ joinedOn }}
          </div>
        </div>
        <div
          class="d-flex flex-column flex-md-row justify-content-start justify-content-md-between"
        >
          <div
            class="flex-fill text-left d-flex align-items-center mb-3 mb-md-0"
          >
            <span class="content-section__summary-counts"
              ><i class="fa fa-file-text-o"></i></span
            >{{ profileSummary.postCount }} Posts
          </div>
          <div
            class="d-flex flex-fill align-items-center justify-content-start justify-content-md-center mb-3 mb-md-0"
          >
            <span class="content-section__summary-counts"
              ><i class="fa fa-star-o"></i></span
            >{{ profileSummary.threadCount }} Threads started
          </div>
          <div
            class="flex-fill d-flex align-items-center justify-content-start justify-content-md-end"
          >
            <span class="content-section__summary-counts"
              ><i class="fa fa-thumbs-o-up"></i></span
            >{{ profileSummary.voteCount }} Votes received
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <LoadingSkeleton width="100%" height="120px" radius="0" />
    </template>
  </section>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import IProfileSummary from "@/domain/IProfileSummary";
import moment from "moment";
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";

@Component({
  components: { LoadingSkeleton },
})
export default class ProfileSummary extends Vue {
  @Prop() private profileSummary!: IProfileSummary | null;
  @Prop() private userId!: string | null;

  get joinedOn(): string | undefined {
    if (this.profileSummary) {
      return moment
        .parseZone(this.profileSummary.dateJoined)
        .format("Do of MMMM YYYY, HH:mm");
    }
    return undefined;
  }
}
</script>

<style scoped lang="scss">
@import "../assets/scss/_base.scss";
.content-section {
  &__avatar {
    min-width: 250px;
  }

  &__summary {
    flex: 1;

    h1 {
      font-size: 1.3rem;
      font-weight: bold;
    }

    .fa {
      font-size: 1.3rem;
      color: #004a71;
      &.fa-file-text-o {
        transform: rotate(180deg);
      }
      &::before {
        justify-content: left;
      }
    }

    &-counts {
      justify-content: center;
      width: 2rem;
    }
  }

  &__joined {
    font-weight: 600;
  }
}
</style>
