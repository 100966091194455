import { render, staticRenderFns } from "./ProfileSubscriptions.vue?vue&type=template&id=9c279b2a&scoped=true"
import script from "./ProfileSubscriptions.vue?vue&type=script&lang=ts"
export * from "./ProfileSubscriptions.vue?vue&type=script&lang=ts"
import style0 from "./ProfileSubscriptions.vue?vue&type=style&index=0&id=9c279b2a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_prettier@2.6.2_vue-template-compiler@2.6.14_webpack@5.91.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c279b2a",
  null
  
)

export default component.exports