<template>
  <div
    class="loading-skeleton"
    :style="{
      width: width,
      height: height,
      'border-radius': radius,
      'background-color': backgroundColor,
    }"
  ></div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class LoadingSkeleton extends Vue {
  @Prop({ required: false, default: "300px" })
  readonly width!: string;

  @Prop({ required: false, default: "100px" })
  readonly height!: string;

  @Prop({ required: false, default: "10px" })
  readonly radius!: string;

  @Prop({ required: false, default: "#dddbdd" })
  readonly backgroundColor!: string;
}
</script>

<style scoped lang="scss">
.loading-skeleton {
  background-color: #dddbdd;
  overflow: hidden;
  display: inline-block;
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
