<template>
  <div>
    <NewsFeedViewSwitcher v-model="option" />
    <div>Selected option: {{ option }}</div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import NewsFeedViewSwitcher, {
  ViewOptions,
} from "@/components/NewsFeedViewSwitcher.vue";

@Component({
  components: { NewsFeedViewSwitcher },
})
export default class ProfileSubscriptionsContainerCatalog extends Vue {
  private option = ViewOptions.EXPANDED;
}
</script>

<style lang="scss" scoped></style>
