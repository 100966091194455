<template>
  <ul class="p-0">
    <li v-for="(message, key) in messages" :key="key">
      <Message
        :message="message"
        :voteEnabled="user && user.id !== message.sender.mailmanId"
      />
    </li>
    <li class="p-0 pt-3 p-md-3" v-if="messages.length === 0">No vote yet.</li>
  </ul>
</template>

<script lang="ts">
import { Component, Inject, Vue } from "vue-property-decorator";
import ProfileSubscriptions from "@/components/ProfileSubscriptions.vue";
import ISecurityService from "@/application/ISecurityService";
import IProfileService from "@/application/IProfileService";
import IMessage from "@/domain/IMessage";
import Message from "@/components/Message.vue";
import IUser from "@/domain/IUser";

@Component({
  components: { Message, ProfileSubscriptions },
})
export default class ProfileVotesView extends Vue {
  @Inject("ISecurityService") securityService!: ISecurityService;
  @Inject("IProfileService") profileService!: IProfileService;

  private messages: Array<IMessage> = [];
  private user: IUser | null = null;

  private async mounted(): Promise<void> {
    this.messages = await this.profileService.getLastVotedPosts();
    this.securityService.getUser().subscribe((user) => {
      if (user) {
        this.user = user;
      }
    });
  }
}
</script>

<style langs="scss" coped>
ul {
  list-style-type: none;
}
</style>
