<template>
  <ul class="p-0">
    <li v-for="(thread, key) in threads" :key="key">
      <Message
        :message="thread"
        :voteEnabled="user && user.id !== thread.sender.mailmanId"
      />
    </li>
    <li class="p-0 pt-3 p-md-3" v-if="threads.length === 0">
      Nothing read yet.
    </li>
  </ul>
</template>

<script lang="ts">
import { Component, Inject, Vue } from "vue-property-decorator";
import ProfileSubscriptions from "@/components/ProfileSubscriptions.vue";
import ISecurityService from "@/application/ISecurityService";
import IProfileService from "@/application/IProfileService";
import Message from "@/components/Message.vue";
import IThread from "@/domain/IThread";
import IUser from "@/domain/IUser";
@Component({
  components: { Message, ProfileSubscriptions },
})
export default class ProfileThreadsView extends Vue {
  @Inject("ISecurityService") securityService!: ISecurityService;
  @Inject("IProfileService") profileService!: IProfileService;

  private threads: Array<IThread> = [];
  private user: IUser | null = null;

  private async mounted(): Promise<void> {
    this.threads = await this.profileService.getLastReadThreads();
    this.securityService.getUser().subscribe((user) => {
      if (user) {
        this.user = user;
      }
    });
  }
}
</script>

<style langs="scss" coped>
ul {
  list-style-type: none;
}
</style>
