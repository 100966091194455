<template>
  <div>
    <LoadingSkeleton v-if="loading" width="100%" height="320px" radius="0" />
    <ProfileSubscriptions v-else :subscriptions="subscriptions" />
  </div>
</template>

<script lang="ts">
import { Component, Inject, Vue } from "vue-property-decorator";
import ProfileSubscriptions from "@/components/ProfileSubscriptions.vue";
import IProfileService from "@/application/IProfileService";
import ISubscription from "@/domain/ISubscription";
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";
@Component({
  components: { LoadingSkeleton, ProfileSubscriptions },
})
export default class ProfileSubscriptionsView extends Vue {
  @Inject("IProfileService") profileService!: IProfileService;

  private subscriptions: Array<ISubscription> = [];
  private loading = true;

  private mounted(): void {
    this.profileService.getSubscriptions().subscribe((subscriptions) => {
      if (subscriptions) {
        this.subscriptions = subscriptions.filter(
          (subscription) => subscription.archived
        );
        this.loading = false;
      }
    });
  }
}
</script>

<style lang="scss" scoped>
ul {
  background-color: #ffffff;
}
</style>
