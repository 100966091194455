<template>
  <div class="w-100">
    <div class="white-background mb-2">
      <Message :message="thread" />
      <Message :message="message" />
    </div>
    <div>
      <Message :message="thread" :messageStyle="threadStyle" />
      <br />
      <Message :message="thread" :expanded="true" :messageStyle="threadStyle" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import ProfileSubscriptions from "@/components/ProfileSubscriptions.vue";
import Message, { MessageStyle } from "@/components/Message.vue";
import IThread from "@/domain/IThread";
import IMessage from "@/domain/IMessage";

@Component({
  components: { Message, ProfileSubscriptions },
})
export default class ProfileSubscriptionsContainerCatalog extends Vue {
  private threadStyle = MessageStyle.BOX_SHADOW;
  private thread: IThread = {
    idHash: "4TIDAXC32ZAM74YKI6SQNBKAU3KBOGUZ",
    subject: "[Apsig-announcement] First SIG Announcement",
    content:
      "In posuere volutpat eros non laoreet. Pellentesque a risus malesuada, viverra nunc nec, elementum elit. Morbi scelerisque massa ex, non auctor massa vestibulum id. Sed nec turpis consectetur, lacinia tortor ut, suscipit risus. Donec ac ante ipsum.\nNam consectetur maximus nunc, tempus mollis ligula tristique non. Sed dictum felis dui. Sed vulputate non enim at commodo. Proin a tellus arcu. Duis convallis felis metus, ac porttitor libero aliquet at. Vivamus quis erat nisi.\nVivamus tempor accumsan sem, quis euismod ipsum cursus sit amet. Morbi commodo lectus urna, at posuere arcu consequat sed. Mauris est sapien, hendrerit vitae est eu, venenatis egestas metus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae;\n\nSed at suscipit nulla, a feugiat risus. Mauris ut malesuada elit. Sed et justo eget sem rhoncus elementum eu eu leo. Sed facilisis euismod nisl quis dapibus. Mauris ac nisi bibendum, gravida nibh eu, fermentum augue. Sed vitae euismod erat. Vivamus est diam, sodales id imperdiet nec, convallis eu lorem. Duis quis eros quam. Quisque tristique lorem dui, mattis tincidunt mi varius non. Pellentesque justo mi, dapibus in est eu, ullamcorper varius nisi. Phasellus ac neque sit amet arcu maximus tincidunt. Ut ligula lacus, rhoncus at libero non, suscipit semper libero. Aliquam mauris nisi, facilisis vitae fringilla eget, elementum eget sapien.",
    htmlContent: "Sample attachment",
    sender: {
      name: "Timothy Zhan",
      mailmanId: "abcdef",
      imageUrl: "/static/community/frontend/img/avatar-sm.svg",
    },
    date: "2022-06-15T01:10:37Z",
    dateActive: "2022-06-15T01:10:37Z",
    likes: 3,
    dislikes: 1,
    mailingList: {
      name: "apsig-announcement@orbit.apnic.net",
      displayName: "Apsig-announcement",
    },
    isThread: true,
    participants: [],
    participantsCount: 3,
    emailsCount: 6,
    threadId: "4TIDAXC32ZAM74YKI6SQNBKAU3KBOGUZ",
    flaggedByModerator: true,
  };

  private message: IMessage = {
    idHash: "4TIDAXC32ZAM74YKI6SQNBKAU3KBOGUZ",
    subject: "[Apsig-announcement] First SIG Announcement",
    content:
      "In posuere volutpat eros non laoreet. Pellentesque a risus malesuada, viverra nunc nec, elementum elit. Morbi scelerisque massa ex, non auctor massa vestibulum id. Sed nec turpis consectetur, lacinia tortor ut, suscipit risus. Donec ac ante ipsum. Nam consectetur maximus nunc, tempus mollis ligula tristique non. Sed dictum felis dui. Sed vulputate non enim at commodo. Proin a tellus arcu. Duis convallis felis metus, ac porttitor libero aliquet at.\nVivamus quis erat nisi. Vivamus tempor accumsan sem, quis euismod ipsum cursus sit amet. Morbi commodo lectus urna, at posuere arcu consequat sed. Mauris est sapien, hendrerit vitae est eu, venenatis egestas metus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae;\n\nSed at suscipit nulla, a feugiat risus. Mauris ut malesuada elit. Sed et justo eget sem rhoncus elementum eu eu leo. Sed facilisis euismod nisl quis dapibus. Mauris ac nisi bibendum, gravida nibh eu, fermentum augue. Sed vitae euismod erat. Vivamus est diam, sodales id imperdiet nec, convallis eu lorem. Duis quis eros quam. Quisque tristique lorem dui, mattis tincidunt mi varius non. Pellentesque justo mi, dapibus in est eu, ullamcorper varius nisi. Phasellus ac neque sit amet arcu maximus tincidunt. Ut ligula lacus, rhoncus at libero non, suscipit semper libero. Aliquam mauris nisi, facilisis vitae fringilla eget, elementum eget sapien.",
    htmlContent: "Sample attachment",
    sender: {
      name: "Timothy Zhan",
      mailmanId: "abcdef",
      imageUrl: "/static/community/frontend/img/avatar-sm.svg",
    },
    date: "2022-06-15T01:10:37Z",
    likes: 3,
    dislikes: 1,
    mailingList: {
      name: "apsig-announcement@orbit.apnic.net",
      displayName: "Apsig-announcement",
    },
    threadId: "4TIDAXC32ZAM74YKI6SQNBKAU3KBOGUZ",
    flaggedByModerator: true,
  };
}
</script>

<style lang="scss" scoped>
.white-background {
  background-color: #ffffff;
}
</style>
