<template>
  <div class="accordion" id="profile-posting-activity-mobile">
    <div class="card">
      <div class="card-header p-0" id="heading_subscriptions">
        <h2 class="mb-0">
          <button
            class="btn btn-link btn-block text-left d-flex justify-content-between collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#collapse_subscriptions"
            aria-expanded="true"
            aria-controls="#collapse_subscriptions"
          >
            <span>My Subscriptions</span>
            <i class="fas fa-angle-up"></i>
          </button>
        </h2>
      </div>

      <div
        id="collapse_subscriptions"
        class="collapse mb-0"
        aria-labelledby="heading_subscriptions"
        data-parent="#profile-posting-activity-mobile"
      >
        <div class="card-body p-0">
          <ProfileSubscriptionsView />
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header p-0" id="heading_posts">
        <h2 class="mb-0">
          <button
            class="btn btn-link btn-block text-left d-flex justify-content-between collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#collapse_posts"
            aria-expanded="true"
            aria-controls="#collapse_posts"
          >
            <span>My Posts</span>
            <i class="fas fa-angle-up"></i>
          </button>
        </h2>
      </div>

      <div
        id="collapse_posts"
        class="collapse"
        aria-labelledby="heading_posts"
        data-parent="#profile-posting-activity-mobile"
      >
        <div class="card-body pt-0">
          <ProfilePostsView />
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header p-0" id="heading_threads">
        <h2 class="mb-0">
          <button
            class="btn btn-link btn-block text-left d-flex justify-content-between collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#collapse_threads"
            aria-expanded="true"
            aria-controls="#collapse_threads"
          >
            <span>Threads I have read</span>
            <i class="fas fa-angle-up"></i>
          </button>
        </h2>
      </div>

      <div
        id="collapse_threads"
        class="collapse"
        aria-labelledby="heading_threads"
        data-parent="#profile-posting-activity-mobile"
      >
        <div class="card-body p-3">
          <ProfileThreadsView />
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header p-0" id="heading_votes">
        <h2 class="mb-0">
          <button
            class="btn btn-link btn-block text-left d-flex justify-content-between collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#collapse_votes"
            aria-expanded="true"
            aria-controls="#collapse_votes"
          >
            <span>My Votes</span>
            <i class="fas fa-angle-up"></i>
          </button>
        </h2>
      </div>

      <div
        id="collapse_votes"
        class="collapse"
        aria-labelledby="heading_votes"
        data-parent="#profile-posting-activity-mobile"
      >
        <div class="card-body p-3">
          <ProfileVotesView />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import ProfileSubscriptionsView from "@/views/ProfileSubscriptionsView.vue";
import ProfilePostsView from "@/views/ProfilePostsView.vue";
import ProfileThreadsView from "@/views/ProfileThreadsView.vue";
import ProfileVotesView from "@/views/ProfileVotesView.vue";

@Component({
  components: {
    ProfileVotesView,
    ProfileThreadsView,
    ProfilePostsView,
    ProfileSubscriptionsView,
  },
})
export default class ProfilePostingActivityMobile extends Vue {}
</script>

<style scoped lang="scss">
@import "../assets/scss/variables";

#profile-posting-activity-mobile {
  width: 100%;
  max-width: $section-max-width;
  display: block;
  @media (min-width: $breakpoint-md) {
    display: none;
  }
  .card {
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.2),
      0px 1px 3px rgba(50, 50, 71, 0.1);
    border: 0;
    .card-header {
      background-color: #ffffff;

      button {
        text-decoration: none;
        i {
          transition-duration: 500ms;
          transition-property: transform;
        }
        &.collapsed {
          i {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  iframe {
    width: 100%;
    min-height: 50vh;
    border-style: none;
  }
}
</style>
