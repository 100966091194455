<template>
  <div class="d-flex align-items-center">
    <div class="mr-2">View:</div>

    <button
      :class="expandedButtonClass"
      @click.prevent="select(viewOptions.EXPANDED)"
    >
      <div></div>
      <div></div>
    </button>

    <button
      :class="collapsedButtonClass"
      @click.prevent="select(viewOptions.COLLAPSED)"
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </button>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

type DOMClass = { [key: string]: boolean };

export enum ViewOptions {
  EXPANDED = "EXPANDED",
  COLLAPSED = "COLLAPSED",
}

@Component
export default class NewsFeedViewSwitcher extends Vue {
  @Prop({ required: true }) private value!: ViewOptions;

  private readonly viewOptions = ViewOptions;
  private selected = ViewOptions.COLLAPSED;

  private mounted(): void {
    this.selected = this.value;
  }

  private get expandedButtonClass(): DOMClass {
    return {
      option: true,
      "option-expanded": true,
      "d-flex": true,
      "flex-column": true,
      "mr-2": true,
      active: this.selected === ViewOptions.EXPANDED,
    };
  }

  private get collapsedButtonClass(): DOMClass {
    return {
      option: true,
      "option-collapsed": true,
      "d-flex": true,
      "flex-column": true,
      active: this.selected === ViewOptions.COLLAPSED,
    };
  }

  private select(option: ViewOptions): void {
    this.$emit("input", option);
  }

  @Watch("value")
  onValueChange(value: ViewOptions) {
    this.$nextTick(() => {
      this.selected = value;
    });
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";
div {
  font-size: 0.9rem;
}

.option {
  border: none;
  background: none;
  & div {
    background-color: $grey-lighter;
  }
  &-expanded div {
    display: block;
    height: 0.7rem;
    width: 1.5rem;
    &:not(:last-child) {
      margin-bottom: 0.1rem;
    }
  }

  &-collapsed div {
    display: block;
    height: 0.2rem;
    width: 1.5rem;
    &:not(:last-child) {
      margin-bottom: 0.25em;
    }
  }

  &.active div {
    background-color: var(--primary);
  }
}
</style>
