<template>
  <div :class="messageClass" ref="messageRef">
    <div class="message__left flex-fill w-100 mb-2 mb-md-0">
      <div class="message__mailing-list mb-0 mb-md-3">
        <a :href="customMessage.mailListUrl">
          <i class="fa fa-inbox mr-3" aria-hidden="true"></i>
          <span>{{ customMessage.mailingList.displayName }}</span>
        </a>
      </div>
      <div class="d-flex">
        <div class="message__avatar d-none d-md-block flex-fill">
          <img
            v-if="customMessage.sender.imageUrl"
            :src="customMessage.sender.imageUrl"
            class="rounded-circle"
            width="45"
          />
          <img v-else src="@/assets/img/avatar-sm.svg" />
        </div>
        <div class="flex-fill">
          <div class="message__subject mb-1">
            <a :href="customMessage.threadUrl">{{ customMessage.subject }}</a>
          </div>
          <div>
            <template v-if="customMessage.senderProfileUrl">
              <a :href="customMessage.senderProfileUrl"
                >by {{ customMessage.sender.name }}</a
              >
            </template>
            <template v-else> by {{ customMessage.sender.name }} </template>
          </div>
          <div
            :style="contentStyle"
            class="message__content mt-2 mb-3 d-none d-md-block"
            v-if="expanded"
            ref="contentRef"
          >
            <MessageContent
              :html-content="customMessage.htmlContent"
              :img-blur-state="sessionShowImgValue"
            ></MessageContent>
          </div>
          <div
            v-if="expanded && displayShowMore"
            class="message__fade"
            ref="fadeRef"
          ></div>
          <div v-if="expanded" class="message__button">
            <button
              v-if="displayShowMore"
              type="button"
              class="mb-1 toggle-label"
              ref="showButton"
              @click="toggleShowMoreContent"
            >
              {{ toggleLabel }}
            </button>
          </div>
          <div
            :class="{
              'd-none': true,
              'd-md-flex': true,
              'justify-content-between': true,
              'justify-content-md-end': !message.isThread,
              'message__activity-expanded': true,
            }"
            v-if="expanded"
          >
            <div v-if="message.isThread">
              <span class="message__right__participants mr-4 mr-md-2">
                <i class="fa fa-user" aria-hidden="true"></i>
                {{ customMessage.participantsCount }}
              </span>
              <span class="message__right__emails">
                <i class="fa fa-comment" aria-hidden="true"></i>
                {{ customMessage.commentCount }}
              </span>
            </div>
            <VoteView
              :message-id-hash="customMessage.idHash"
              :mlist-name="customMessage.mailingList.name"
              :likes="customMessage.likes"
              :dislikes="customMessage.dislikes"
              :enabled="voteEnabled"
            />
            <div>
              <ReportPostUserModalView
                :m-list="customMessage.mailingList.name"
                :id-hash="customMessage.idHash"
                :flagged-by-moderator="customMessage.flaggedByModerator"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="message__right flex-fill d-flex w-100">
      <div class="message__extra flex-fill">
        <div
          :class="{
            'text-left': true,
            'text-md-center': message.isThread,
            'mb-2': true,
            'text-md-right': !message.isThread,
          }"
        >
          {{ customMessage.date }}
        </div>
        <div :class="messageActivityRightClass" v-if="!expanded">
          <div>
            <VoteView
              :message-id-hash="customMessage.idHash"
              :mlist-name="customMessage.mailingList.name"
              :likes="customMessage.likes"
              :dislikes="customMessage.dislikes"
              :enabled="voteEnabled"
            />
          </div>
          <div v-if="message.isThread">
            <span class="message__right__participants mr-4 mr-md-2">
              <i class="fa fa-user" aria-hidden="true"></i>
              {{ customMessage.participantsCount }}
            </span>
            <span class="message__right__emails">
              <i class="fa fa-comment" aria-hidden="true"></i>
              {{ customMessage.commentCount }}
            </span>
          </div>
        </div>
        <div v-if="!expanded">
          <ReportPostUserModalView
            :m-list="customMessage.mailingList.name"
            :id-hash="customMessage.idHash"
            :flagged-by-moderator="customMessage.flaggedByModerator"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import IMessage from "@/domain/IMessage";
import IThread from "@/domain/IThread";
import moment from "moment";
import VoteView from "../views/VoteView.vue";
import MessageContent from "@/components/MessageContent.vue";
import IBlurImageToggleService from "@/application/IBlurImageToggleService";
import ReportPostUserModalView from "@/components/ReportPostUserModalView.vue";

type CustomMessage = (IMessage | IThread) & {
  mailListUrl: string;
  threadUrl: string;
  senderProfileUrl: string | undefined;
  commentCount: number;
};

type DOMClass = { [key: string]: boolean };

export enum MessageStyle {
  SOLID_BORDER_BOTTOM = "message__solid-border-bottom",
  BOX_SHADOW = "message__box-shadow",
}

@Component({
  components: { VoteView, MessageContent, ReportPostUserModalView },
})
export default class Message extends Vue {
  @Inject("IBlurImageToggleService")
  blurImageToggleService!: IBlurImageToggleService;
  @Prop({ required: true }) private message!: IMessage | IThread;
  @Prop({ required: false, default: false }) private expanded!: boolean;
  @Prop({ required: false, default: () => MessageStyle.SOLID_BORDER_BOTTOM })
  private messageStyle!: MessageStyle;
  @Prop({ required: false, default: false })
  private voteEnabled!: boolean;
  private isToggle = false;
  private displayShowMore = false;
  sessionShowImgValue: boolean | null = null;

  private mounted() {
    this.blurImageToggleService.getShowImg().subscribe((showImg: boolean) => {
      this.sessionShowImgValue = showImg;
    });
    this.newsFeedDefaultView();
    this.newTabAllLinks();
  }
  @Watch("isToggle")
  isToggleChanged(isToggle: boolean) {
    if (!isToggle) {
      const messageDiV = this.$refs.messageRef as HTMLElement;
      messageDiV.scrollIntoView({
        behavior: "smooth",
        inline: "nearest",
      });
    }
  }
  get messageClass(): DOMClass {
    return {
      message: true,
      "w-100": true,
      "p-3": true,
      "pb-3": true,
      "pb-md-4": true,
      "d-flex": true,
      "flex-column": true,
      "flex-md-row": true,
      "align-items-center": !this.expanded,
      [this.messageStyle]: true,
    };
  }
  get messageActivityRightClass(): DOMClass {
    return {
      "d-flex": true,
      "justify-content-between": true,
      "justify-content-md-end": !(this.message as IThread).isThread,
    };
  }
  get customMessage(): CustomMessage {
    const messageAsThread = this.message as IThread;
    const commentCount = messageAsThread.isThread
      ? messageAsThread.emailsCount - 1
      : 0;
    const date = messageAsThread.isThread
      ? messageAsThread.dateActive
      : this.message.date;
    return {
      ...this.message,
      date: moment.parseZone(date).format("D MMM YYYY h:mm a"),
      mailListUrl: `/mailing-list/${this.message.mailingList.name}/`,
      threadUrl: `/hyperkitty/list/${this.message.mailingList.name}/thread/${this.message.threadId}/`,
      senderProfileUrl: this.message.sender.mailmanId
        ? `/profile/${this.message.sender.mailmanId}`
        : undefined,
      commentCount,
    };
  }

  get contentStyle() {
    const content = this.$refs.contentRef as HTMLElement;
    return this.isToggle
      ? { maxHeight: content.scrollHeight + "px" }
      : { height: "inherit" };
  }

  get toggleLabel(): string {
    return this.expanded && !this.isToggle ? "Show More" : "Show Less";
  }

  private newsFeedDefaultView() {
    this.$nextTick(() => {
      const content = this.$refs.contentRef as HTMLElement;
      if (content.scrollHeight > 600) {
        this.displayShowMore = true;
      }
    });
  }

  private toggleShowMoreContent() {
    this.isToggle = !this.isToggle;
  }

  private newTabAllLinks(): void {
    const newsFeedLinks = Array.from(
      this.$el.querySelectorAll<HTMLElement>(".message__content a")
    );
    newsFeedLinks.forEach((link) => {
      link.setAttribute("target", "_blank");
    });
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";

.message {
  background-color: #ffffff;
  &__solid-border-bottom {
    border-bottom: 0.1rem solid $grey-border;
  }

  &__box-shadow {
    box-shadow: $box-shadow;
  }

  &__mailing-list {
    color: var(--primary);
    font-weight: 600;
  }

  &__avatar {
    max-width: 4rem;
    min-width: 4rem;
  }

  &__subject {
    a {
      font-weight: 600;
      color: $font-color-default;
    }
  }

  &__content {
    word-break: break-word;
    overflow: hidden !important;
    max-height: 600px;
    max-width: 994px;
    & ::v-deep .image-container img {
      max-width: 100% !important;
    }

    & ::v-deep p:not([style]):not([class]):not(:has(> *:not(:empty))) {
      white-space: pre-wrap;
    }

    & ::v-deep .image-icon {
      position: relative;
      top: 10px;
      left: 10px;
    }
    & ::v-deep ul {
      list-style-type: disc !important;
    }
    & ::v-deep blockquote {
      font-size: 16px !important;
    }
    & ::v-deep pre {
      white-space: pre-wrap; /* Since CSS 2.1 */
      white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
      white-space: -o-pre-wrap; /* Opera 7 */
    }
    & ::v-deep h1 {
      font-size: 16px;
      color: black;
      margin: 16px 0 16px 0;
      font-weight: bold;
    }
    & ::v-deep h2 {
      font-size: 16px;
      color: black;
      margin: 16px 0 16px 0;
      font-weight: bold;
    }
    & ::v-deep h3 {
      font-size: 16px;
      color: black;
      margin: 16px 0 16px 0;
      font-weight: bold;
    }
    & ::v-deep h4 {
      font-size: 16px;
      color: black;
      margin: 16px 0 16px 0;
      font-weight: bold;
    }
    & ::v-deep h5 {
      font-size: 16px;
      color: black;
      margin: 16px 0 16px 0;
      font-weight: bold;
    }
    & ::v-deep h6 {
      font-size: 16px;
      color: black;
      margin: 16px 0 16px 0;
      font-weight: bold;
    }
    & ::v-deep th {
      text-align: center !important;
      border: none;
      padding: 5px;
      margin-bottom: 10px;
    }
    & ::v-deep td {
      max-width: 994px;
      text-align: center !important;
      border: none;
      padding: 5px;
      margin-bottom: 10px;
    }
    & ::v-deep blockquote:before {
      content: "...";
      font-size: 1em;
      position: relative;
      right: 0.5em;
      color: #007bbd;
    }

    & ::v-deep blockquote {
      position: relative;
      border-left: 6.554px solid #bbbbbb;
    }
    ::v-deep blockquote > * {
      display: none;
    }
    & ::v-deep blockquote:hover:before {
      cursor: pointer;
    }
  }
  &__button {
    width: auto;
    display: block;
    text-align: center !important;
    & ::v-deep .toggle-label {
      background: none;
      border: none;
      color: #007bbd;
    }
  }
  &__fade {
    position: relative;
    bottom: 8em;
    height: 8em;
    margin-bottom: -130px;
    background: -webkit-linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background-image: -moz-linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background-image: -o-linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background-image: linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background-image: -ms-linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }
  &__right {
    @media (min-width: $breakpoint-md) {
      max-width: 15rem;
    }

    &__participants,
    &__emails {
      i {
        color: $grey-lighter;
      }
    }
  }

  &__activity-expanded {
    width: 22rem;
    .report-btn {
      color: #007bbd;
      padding: 0;
      background: none;
      border: none;
    }
  }
  .message__extra {
    width: 240px;
    .report-btn {
      color: #007bbd;
      padding: 0;
      background: none;
      border: none;
    }
  }
  .message__left {
    max-width: 1008px;
  }
}
</style>
