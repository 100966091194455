var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"messageRef",class:_vm.messageClass},[_c('div',{staticClass:"message__left flex-fill w-100 mb-2 mb-md-0"},[_c('div',{staticClass:"message__mailing-list mb-0 mb-md-3"},[_c('a',{attrs:{"href":_vm.customMessage.mailListUrl}},[_c('i',{staticClass:"fa fa-inbox mr-3",attrs:{"aria-hidden":"true"}}),_c('span',[_vm._v(_vm._s(_vm.customMessage.mailingList.displayName))])])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"message__avatar d-none d-md-block flex-fill"},[(_vm.customMessage.sender.imageUrl)?_c('img',{staticClass:"rounded-circle",attrs:{"src":_vm.customMessage.sender.imageUrl,"width":"45"}}):_c('img',{attrs:{"src":require("@/assets/img/avatar-sm.svg")}})]),_c('div',{staticClass:"flex-fill"},[_c('div',{staticClass:"message__subject mb-1"},[_c('a',{attrs:{"href":_vm.customMessage.threadUrl}},[_vm._v(_vm._s(_vm.customMessage.subject))])]),_c('div',[(_vm.customMessage.senderProfileUrl)?[_c('a',{attrs:{"href":_vm.customMessage.senderProfileUrl}},[_vm._v("by "+_vm._s(_vm.customMessage.sender.name))])]:[_vm._v(" by "+_vm._s(_vm.customMessage.sender.name)+" ")]],2),(_vm.expanded)?_c('div',{ref:"contentRef",staticClass:"message__content mt-2 mb-3 d-none d-md-block",style:(_vm.contentStyle)},[_c('MessageContent',{attrs:{"html-content":_vm.customMessage.htmlContent,"img-blur-state":_vm.sessionShowImgValue}})],1):_vm._e(),(_vm.expanded && _vm.displayShowMore)?_c('div',{ref:"fadeRef",staticClass:"message__fade"}):_vm._e(),(_vm.expanded)?_c('div',{staticClass:"message__button"},[(_vm.displayShowMore)?_c('button',{ref:"showButton",staticClass:"mb-1 toggle-label",attrs:{"type":"button"},on:{"click":_vm.toggleShowMoreContent}},[_vm._v(" "+_vm._s(_vm.toggleLabel)+" ")]):_vm._e()]):_vm._e(),(_vm.expanded)?_c('div',{class:{
            'd-none': true,
            'd-md-flex': true,
            'justify-content-between': true,
            'justify-content-md-end': !_vm.message.isThread,
            'message__activity-expanded': true,
          }},[(_vm.message.isThread)?_c('div',[_c('span',{staticClass:"message__right__participants mr-4 mr-md-2"},[_c('i',{staticClass:"fa fa-user",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.customMessage.participantsCount)+" ")]),_c('span',{staticClass:"message__right__emails"},[_c('i',{staticClass:"fa fa-comment",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.customMessage.commentCount)+" ")])]):_vm._e(),_c('VoteView',{attrs:{"message-id-hash":_vm.customMessage.idHash,"mlist-name":_vm.customMessage.mailingList.name,"likes":_vm.customMessage.likes,"dislikes":_vm.customMessage.dislikes,"enabled":_vm.voteEnabled}}),_c('div',[_c('ReportPostUserModalView',{attrs:{"m-list":_vm.customMessage.mailingList.name,"id-hash":_vm.customMessage.idHash,"flagged-by-moderator":_vm.customMessage.flaggedByModerator}})],1)],1):_vm._e()])])]),_c('div',{staticClass:"message__right flex-fill d-flex w-100"},[_c('div',{staticClass:"message__extra flex-fill"},[_c('div',{class:{
          'text-left': true,
          'text-md-center': _vm.message.isThread,
          'mb-2': true,
          'text-md-right': !_vm.message.isThread,
        }},[_vm._v(" "+_vm._s(_vm.customMessage.date)+" ")]),(!_vm.expanded)?_c('div',{class:_vm.messageActivityRightClass},[_c('div',[_c('VoteView',{attrs:{"message-id-hash":_vm.customMessage.idHash,"mlist-name":_vm.customMessage.mailingList.name,"likes":_vm.customMessage.likes,"dislikes":_vm.customMessage.dislikes,"enabled":_vm.voteEnabled}})],1),(_vm.message.isThread)?_c('div',[_c('span',{staticClass:"message__right__participants mr-4 mr-md-2"},[_c('i',{staticClass:"fa fa-user",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.customMessage.participantsCount)+" ")]),_c('span',{staticClass:"message__right__emails"},[_c('i',{staticClass:"fa fa-comment",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.customMessage.commentCount)+" ")])]):_vm._e()]):_vm._e(),(!_vm.expanded)?_c('div',[_c('ReportPostUserModalView',{attrs:{"m-list":_vm.customMessage.mailingList.name,"id-hash":_vm.customMessage.idHash,"flagged-by-moderator":_vm.customMessage.flaggedByModerator}})],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }