import Vue from "vue";
import Catalog from "./Catalog.vue";
import VueRouter from "vue-router";
import routerFactory from "./router/index";

Vue.use(VueRouter);
Vue.config.productionTip = false;

new Vue({
  render: (h) => h(Catalog),
  router: routerFactory(),
}).$mount("#wrapper");
