<template>
  <section class="content-section d-flex flex-column flex-md-row mb-5">
    <template v-if="profile">
      <div class="profile-details__left flex-fill d-flex flex-column">
        <div class="d-flex">
          <h1 class="flex-fill">My Details</h1>
          <a
            href="https://profile.apnic.net"
            class="flex-fill text-right pr-0 pr-md-4 pr-xl-5 text-nowrap"
            >Edit in MyAPNIC <span class="fa fa-external-link"></span
          ></a>
        </div>
        <div class="d-flex flex-column">
          <div class="d-flex flex-column flex-md-row mb-4">
            <div
              class="flex-fill profile-details__label text-left text-md-right mr-3"
            >
              Display Name
            </div>
            <div>{{ profile.displayName }}</div>
          </div>
          <div class="d-flex flex-column flex-md-row mb-4">
            <div
              class="flex-fill profile-details__label text-left text-md-right mr-3"
            >
              Full Name
            </div>
            <div>{{ profile.fullName }}</div>
          </div>
          <div
            class="d-flex flex-column flex-md-row mb-4"
            v-for="(item, key) in additionalInformationItems"
            :key="`ai_${key}`"
          >
            <div
              class="flex-fill profile-details__label text-left text-md-right mr-3"
            >
              {{ item.label }}
            </div>
            <div v-if="isWebLink(item.value)">
              <a :href="item.value" target="_blank">{{ item.value }}</a>
            </div>
            <div v-else>
              {{ item.value }}
            </div>
          </div>
        </div>
      </div>
      <div class="profile-details__right flex-fill">
        <div class="d-flex flex-column">
          <div class="d-flex flex-column flex-md-row mb-4">
            <div
              class="flex-fill profile-details__label text-left text-md-right mr-3"
            >
              About me
            </div>
            <div class="flex-fill">
              <div class="textarea-wordcount">
                <textarea
                  ref="aboutMe"
                  v-model="aboutMe"
                  :disabled="textAreaDisabled"
                />
                <div class="textarea-wordcount__count">
                  {{ aboutMeRemaining }} characters remaining
                </div>
              </div>
              <button
                class="btn btn-primary w-100"
                @click.prevent="save"
                :disabled="saveButtonDisabled"
              >
                Save Changes
                <div
                  v-if="saving"
                  class="spinner-border spinner-border-sm"
                  role="status"
                >
                  <span class="sr-only">Saving...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="w-100 p-0 p-md-2">
        <LoadingSkeleton width="100%" height="375px" radius="0" />
      </div>
    </template>
  </section>
</template>

<script lang="ts">
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import IProfile from "@/domain/IProfile";
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";
import { ProfileDetailsMixIn } from "@/components/ProfileDetailsMixIn";

const ABOUT_ME_MAX_CHARACTERS = 500;

@Component({
  components: { LoadingSkeleton },
})
export default class ProfileDetails extends mixins(ProfileDetailsMixIn) {
  private aboutMe = "";
  private saving = false;

  get aboutMeRemaining(): number {
    return ABOUT_ME_MAX_CHARACTERS - this.aboutMe.length;
  }

  get oldAboutMe(): string {
    return this.profile?.aboutMe ?? "";
  }

  get textAreaDisabled(): boolean {
    return this.saving;
  }

  get saveButtonDisabled(): boolean {
    return this.oldAboutMe === this.aboutMe || this.saving;
  }

  private save(): void {
    this.saving = true;
    this.$emit("onSave", { aboutMe: this.aboutMe } as { aboutMe: string });
  }

  @Watch("profile")
  onProfileChanged(profile: IProfile) {
    this.saving = false;
    this.aboutMe = profile?.aboutMe ?? "";
  }

  @Watch("aboutMe")
  onAboutMeChanged(newValue: string) {
    this.$nextTick(() => {
      if (newValue.length > ABOUT_ME_MAX_CHARACTERS) {
        this.aboutMe = newValue.substring(0, ABOUT_ME_MAX_CHARACTERS);
      }
    });
  }
}
</script>

<style scoped lang="scss">
@import "../assets/scss/_variables.scss";
@import "../assets/scss/_base.scss";

.content-section {
  h1 {
    font-size: 1.1rem;
    font-weight: normal;
  }

  .fa {
    transform: scale(0.8);
  }

  a {
    cursor: pointer;
  }

  .profile-details {
    &__label {
      max-width: 110px;
      font-weight: 600;
      text-transform: capitalize;
    }

    &__right {
      .textarea-wordcount {
        width: 100%;
        height: 20rem;
        textarea {
          width: 100%;
          height: 19rem;
          border: 1px solid $grey-border;
          padding: 1rem 1rem 1.5rem 1rem;
          resize: none;

          &:focus {
            outline: none;
          }
        }

        &__count {
          position: relative;
          margin-top: -2rem;
          margin-right: 1rem;
          text-align: right;
          color: var(--gray);
          user-select: none;
          background-color: white;
          margin-left: 1rem;
          padding-bottom: 0.2rem;

          @media (min-width: $breakpoint-md) {
            padding-bottom: 0.25rem;
          }
        }
      }

      button {
        &:disabled {
          background-color: var(--gray);
          border-color: var(--gray);
        }
      }
    }
  }

  @media (min-width: $breakpoint-md) {
    h1 {
      font-size: 1.4rem;
    }

    .profile-details__left {
      width: 50%;
      max-width: 50%;
      border-right: 1px solid $grey-border;
    }
  }
}
</style>
