<template>
  <div class="html-content">
    <!-- sanitizeHtmlContent uses DOMPurify to make this safe -->
    <!-- nosemgrep avoid-v-html -->
    <div :class="imgClass" v-html="sanitizeHtmlContent" ref="messageRef"></div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import SanitizeHtmlContentService from "@/infrastructure/SanitizeHtmlContentService";

type DOMClass = { [key: string]: boolean };
@Component
export default class MessageContent extends Vue {
  @Prop({ required: true }) htmlContent!: string;
  @Prop({ required: true }) imgBlurState!: boolean | null;
  mounted() {
    this.imageRules();
    this.newTabAllLinks();
  }
  get sanitizeHtmlContent(): string {
    return SanitizeHtmlContentService.sanitizeHtmlContent(
      String(this.htmlContent)
    );
  }
  get imgClass(): DOMClass {
    return {
      "img-class": !this.imgBlurState,
    };
  }
  private imageRules() {
    const newsFeedMaxWidth = 944;
    const messageDiv = this.$refs.messageRef as HTMLElement;
    const newsFeedImage = messageDiv.querySelectorAll<HTMLImageElement>("img");
    newsFeedImage.forEach((img) => {
      if (img.scrollWidth > newsFeedMaxWidth) {
        (img as HTMLImageElement).classList.add("img-style-lg");
      } else if (img.scrollWidth >= newsFeedMaxWidth * 0.5) {
        (img as HTMLImageElement).classList.add("img-style-md");
      } else if (img.scrollWidth < newsFeedMaxWidth * 0.5) {
        (img as HTMLImageElement).classList.add("img-style-sm");
      }
    });
  }
  private newTabAllLinks(): void {
    this.$nextTick(() => {
      const singleThreadLinks = this.$refs.messageRef as HTMLElement;
      const aElement = singleThreadLinks.querySelectorAll("a");
      aElement.forEach((link) => {
        link.setAttribute("target", "_blank");
      });
    });
  }
}
</script>
<style scoped lang="scss">
@import "../assets/scss/variables.scss";
.img-class {
  ::v-deep img {
    filter: blur(12px);
  }
}
.html-content {
  & ::v-deep .img-style-sm {
    padding: 8px;

    @media (min-width: $breakpoint-md) {
      max-width: $breakpoint-sm;
    }
  }

  & ::v-deep .img-style-md {
    padding: 8px;
    display: block;
    margin: 0 auto;

    @media (min-width: $breakpoint-lg) {
      max-width: $breakpoint-md;
    }
  }
  & ::v-deep .img-style-lg {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    object-fit: contain;
    padding: 8px;
    @media (min-width: $breakpoint-lg) {
      max-width: $breakpoint-md;
    }
  }
}
</style>
