<template>
  <ul class="p-0">
    <li v-for="(message, key) in messages" :key="key">
      <Message
        :message="message"
        :voteEnabled="user && user.id !== message.sender.mailmanId"
      />
    </li>
    <li class="p-0 pt-3 p-md-3" v-if="messages.length === 0">No posts yet.</li>
  </ul>
</template>

<script lang="ts">
import Vue_, { VueConstructor } from "vue";
import IMessage from "@/domain/IMessage";
import Message from "@/components/Message.vue";
import IUser from "@/domain/IUser";
import ISecurityService from "@/application/ISecurityService";
import IProfileService from "@/application/IProfileService";

type Data = {
  messages: Array<IMessage>;
  user: IUser | null;
};

interface InjectedKeys {
  securityService: ISecurityService;
  profileService: IProfileService;
}

const Vue = Vue_ as VueConstructor<Vue_ & InjectedKeys>;

export default Vue.extend({
  name: "ProfilePostsView",
  components: { Message },
  inject: {
    securityService: { from: "ISecurityService" },
    profileService: { from: "IProfileService" },
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data(): Data {
    return {
      messages: [],
      user: null,
    };
  },
  async mounted(): Promise<void> {
    this.messages = await this.profileService.getLastPosts();
    this.securityService.getUser().subscribe((user) => {
      if (user) {
        this.user = user;
      }
    });
  },
});
</script>

<style langs="scss" coped>
ul {
  list-style-type: none;
}
</style>
